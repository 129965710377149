import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as SendIcon } from "./assets/icons/send-icon.svg"; 
import { useClipboardContext } from "./ClipboardContext"; 
import { marked } from "marked";
import InfoModal from "./InfoModal"; 
import "./Chat.css";
import io from "socket.io-client"; 

const Chat = () => {
  const [showNoticeModal, setShowNoticeModal] = useState(true); 
  const [conversation, setConversation] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); 
  const conversationEndRef = useRef(null);
  const conversationRef = useRef([]);
  const { addClipboardItem } = useClipboardContext(); 
  const [responseStyle, setResponseStyle] = useState("Select response style");
  const [responseLength, setResponseLength] = useState("Select response length");
  const [responseFormat, setResponseFormat] = useState("Select response format");

  const typingQueueRef = useRef([]);
  const isTypingRef = useRef(false);

  const [socket, setSocket] = useState(null); 

  useEffect(() => {
    const newSocket = io(); 
    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("Connected to Socket.IO server");
    });

    newSocket.on("disconnect", () => {
      console.log("Disconnected from Socket.IO server");
    });

    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (!socket) return;

    socket.on("response_chunk", (data) => {
      console.log("Chunk received:", data.chunk);

      if (data.chunk === "[DONE]") {
        isTypingRef.current = false; 
        console.log("Streaming complete");
        return;
      }

      if (data.chunk.startsWith("[Error]")) {
        return;
      }

      if (data.chunk && data.chunk !== "[DONE]") {
        typingQueueRef.current.push(data.chunk.replace(/\[DONE\]/g, '')); 
        if (!isTypingRef.current) {
          typeNextChunk();
        }
      }
    });

    socket.on("connect_error", (err) => {
      console.error("Socket connection error:", err);
    });

    return () => {
      socket.off("response_chunk");
      socket.off("connect_error");
    };
  }, [socket]);

  const typeNextChunk = () => {
    if (typingQueueRef.current.length === 0) {
      isTypingRef.current = false;
      return;
    }

    isTypingRef.current = true;
    const currentChunk = typingQueueRef.current.shift();
    let index = 0;

    const typeCharacter = () => {
      if (index < currentChunk.length) {
        const nextChar = currentChunk[index];
        index++;
        setConversation((prev) => {
          const updated = [...prev];
          const lastMessage = updated[updated.length - 1];

          if (lastMessage && lastMessage.role === "assistant") {
            updated[updated.length - 1] = {
              ...lastMessage,
              content: lastMessage.content + nextChar,
            };
          } else {
            updated.push({
              role: "assistant",
              content: nextChar,
            });
          }
          return updated;
        });
        setTimeout(typeCharacter, 5);
      } else {
        typeNextChunk();
      }
    };

    typeCharacter();
  };

  const scrollToBottom = () => {
    if (conversationEndRef.current) {
      conversationEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest", 
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const style =
      responseStyle === "Select response style" ? "formal" : responseStyle;
    const length =
      responseLength === "Select response length" ? "long" : responseLength;
    const format =
      responseFormat === "Select response format"
        ? "bullet_points"
        : responseFormat;

    if (userInput.trim().length < 20) {
      setErrorMessage("Your message must be at least 20 characters long.");
      return;
    }

    setErrorMessage("");

    const newMessage = { role: "user", content: "\n" + userInput };

    conversationRef.current = [...conversationRef.current, newMessage];

    setConversation([...conversationRef.current]);

    setUserInput(""); 

    const assistantPlaceholder = { role: "assistant", content: "" };
    conversationRef.current = [
      ...conversationRef.current,
      assistantPlaceholder,
    ];
    setConversation([...conversationRef.current]);

    if (socket) {
      console.log("Emitting send_message event with data:", {
        instructions: "You are a helpful assistant.",
        user_input: userInput,
        response_style: style,
        response_length: length,
        response_format: format,
      });

      socket.emit("send_message", {
        instructions: "You are a helpful assistant.",
        user_input: userInput,
        response_style: style,
        response_length: length,
        response_format: format,
      });
    } else {
      console.error("Socket not initialized");
      setConversation((prev) => [
        ...prev,
        {
          role: "assistant",
          content: "[Error] Socket not initialized.",
          type: "error",
        },
      ]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleClear = () => {
    fetch("/api/clear_chat", { method: "POST" })
      .then((response) => {
        if (response.ok) {
          setConversation([]); 
          conversationRef.current = []; 
          setUserInput(""); 
        }
      })
      .catch((error) => console.error("Error clearing chat:", error));
  };


  const renderMessageContent = (msg) => {
    if (msg.role === "assistant") {
      const processedContent = msg.content
        .replace(/\n/g, "") 
        .replace(/(?<!\n)- /g, "\n- ") 
        .replace(/(?<!\n)\s{2}- /g, "\n  - ") 
        .replace(/(?<!\n)\s{4}- /g, "\n    - "); 
  
      const htmlContent = marked(processedContent, {
        headerIds: false,
        mangle: false,
      });
  
      return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    }
    return <span style={{ whiteSpace: "pre-wrap" }}>{msg.content}</span>;
  };


  return (
    <div className="chat-container">

      <main className="chat-main">
        <header className="chat-header" style={{ alignItems: "center" }}>
          <h2 className="chat-title roboto-light">CHAT</h2>
        </header>
        <p className="chat-description">
          Regxplora’s Chat feature enables direct interaction with Regxplora's
          up-to-date news database which currently houses updates from January 1, 2024 onwards. Utilizing a bespoke information retrieval
          technique, you can pose specific questions on latest updates, trends
          across regions and other focused insights. Customize responses by
          style, length, and format tailored to your preferences, and easily
          copy content to the editor for further refinement. Stay tuned for the
          launch of a dedicated research capability during the first half of 2025 for an even more
          systematic research and analysis of financial regulatory and central
          bank intelligence.
        </p>

        <div className="chat-columns">
          <div className="chat-left-column">
            <div className="chat-output-container">
              <div id="conversation" className="chat-output-field">
                {conversation.map((msg, index) => (
                  <div key={index} className={`message ${msg.role}`}>
                    <strong className={`message-role ${msg.role}`}>
                      {msg.role === "user" ? "User" : "Assistant"}:
                    </strong>
                    <div className="message-content">
                      {renderMessageContent(msg)}{" "}
                    </div>
                  </div>
                ))}
                <div ref={conversationEndRef} />
              </div>
            </div>

            <div className="chat-input-container">
              <form
                id="chat-form"
                onSubmit={handleSubmit}
                className="input-with-button"
              >
                <textarea
                  name="user_input"
                  className="chat-input-field"
                  placeholder="Enter your question here..."
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyDown={handleKeyDown} 
                  rows="3"
                ></textarea>
                <button type="submit" className="send-button">
                  <SendIcon className="send-icon" />
                </button>
              </form>
              {errorMessage && (
                <p className="error-message">{errorMessage}</p> 
              )}
            </div>

            <button
              type="button"
              className="action-button action-button-mobile"
              onClick={(e) => {
                e.stopPropagation();
                const selectedText = window.getSelection().toString();
                if (selectedText) {
                  addClipboardItem(selectedText);
                } else {
                  console.warn("No text selected to copy.");
                }
              }}
            >
              Copy selection to clipboard
            </button>
            <button
              type="button"
              className="action-button action-button-mobile"
              onClick={handleClear}
            >
              Clear chat
            </button>
          </div>

          <div className="chat-right-column">
            <select
              className="response-options-dropdown"
              value={responseStyle}
              onChange={(e) => setResponseStyle(e.target.value)}
            >
              <option>Select response style</option>
              <option value="formal">Formal</option>
              <option value="informal">Casual</option>
            </select>

            <select
              className="response-options-dropdown"
              value={responseLength}
              onChange={(e) => setResponseLength(e.target.value)}
            >
              <option>Select response length</option>
              <option value="short">Concise</option>
              <option value="long">Detailed</option>
            </select>

            <select
              className="response-options-dropdown"
              value={responseFormat}
              onChange={(e) => setResponseFormat(e.target.value)}
            >
              <option>Select response format</option>
              <option value="bullet_points">Bullet points</option>
              <option value="paragraph">Continuous prose</option>
            </select>
            <button
              type="button"
              className="action-button"
              onClick={(e) => {
                e.stopPropagation();
                const selectedText = window.getSelection().toString();
                if (selectedText) {
                  addClipboardItem(selectedText);
                } else {
                  console.warn("No text selected to copy.");
                }
              }}
            >
              Copy selection to clipboard
            </button>
            <button
              type="button"
              className="action-button"
              onClick={handleClear}
            >
              Clear chat
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Chat;