import React from "react";
import "./Developerresources_overview.css";

const Developerresources_general = () => {
  return (
    <div className="developerresources-container">
      <main className="developerresources-main">
        <header className="developerresources-header" style={{ alignItems: "center" }}>
          <h2 className="developerresources-title">DEVELOPER RESOURCES</h2>
        </header>

        <p className="developerresources-description">
        We invite interested developers to programmatically extract intelligence from Regxplora's database through dedicated API endpoints.
        Currently, we offer an API endpoint for accessing updates from Regxplora's News Tracker.
        As we develop new specialized trackers, additional API endpoints will be made available.
        To use the API, you will need an API key, which can be requested in the "My API Keys" section.
        Please note that only registered Regxplora users are eligible to request API keys.
        </p>

        <h2 className="developerresources-subtitle">API documentation</h2>
        <p className="developerresources-description">
        Coming shortly
        </p>
      </main>
    </div>
  );
};

export default Developerresources_general;