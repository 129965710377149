import React, { useState, useEffect } from 'react';
import './APIKeys.css';

function APIKeys() {
  const [formData, setFormData] = useState({
    source: '',
    purpose: '',
    volume: ''
  });
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [showFullKey, setShowFullKey] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);

  // Fetch existing API keys on mount
  useEffect(() => {
    async function fetchKeys() {
      try {
        const response = await fetch('/api/my_api_keys', {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setApiKeys(data.apiKeys);
        } else {
          setError('Unable to load your API keys at this time.');
        }
      } catch (err) {
        console.error(err);
        setError('An unexpected error occurred while loading your API keys.');
      }
    }
    fetchKeys();
  }, []);

  const handleCopyKey = (key) => {
    navigator.clipboard.writeText(key).then(() => {
      console.log('Copied to clipboard:', key);
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    // Create a new API key
    const response = await fetch('/api/my_api_keys', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(formData),
    });

    const data = await response.json();
    if (response.ok) {
      setMessage('API key request successfully submitted.');
      if (data.createdKey) {
        setApiKeys([...apiKeys, data.createdKey]);
      }
    } else {
      setError(data.error || 'Failed to request API key.');
      setMessage(
        'The API key request could not be handled at this time. Please try again later.'
      );
    }
  };

  function maskApiKey(key) {
    if (!key) return '';
    if (key.length <= 5) return key;
    const visiblePart = key.substring(0, 5);
    const hiddenPart = key.substring(5).replace(/./g, '•');
    return visiblePart + hiddenPart;
  }

  return (
    <div className="apikey-container">
      <main className="apikey-main">
        <header className="apikey-header">
          <h2 className="apikey-title roboto-light">MY API KEYS</h2>
        </header>

        <p className="apikey-description">
          In this section you can manage your Regxplora API keys. Currently, we
          provide an API endpoint for retrieving updates from Regxplora’s News
          Tracker. As new specialized trackers become available, we will expand
          our suite of APIs. While use of the API is currently free to use, it is
          subject to daily limits. Additionally, to prevent misuse, users must
          disclose their intended purpose when requesting a new API key.
        </p>

        <label>Request a new API key</label>

        <p className="apikey-description">
          Please fill out the form below to request a new API key. We will
          review your request and will inform you once your API key is ready for
          use.
        </p>

        <form className="apikey-request-form" onSubmit={handleSubmit}>
          <div className="apikey-request-form-row">
            <div className="apikey-request-form-group">
              <select
                id="source"
                name="source"
                className="apikey-request-input"
                value={formData.source}
                onChange={handleChange}
                required
              >
                <option value="">Select information source</option>
                <option value="Regxplora News Tracker">
                  Regxplora News Tracker
                </option>
              </select>
            </div>
          </div>

          <div className="apikey-request-form-row">
            <div className="apikey-request-form-group">
              <textarea
                id="purpose"
                name="purpose"
                className="apikey-request-input apikey-request-purpose"
                placeholder="Please provide details for your intended use of the API Key"
                value={formData.purpose}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="apikey-request-form-row">
            <div className="apikey-request-form-group">
              <select
                id="volume"
                name="volume"
                className="apikey-request-input"
                value={formData.volume}
                onChange={handleChange}
              >
                <option value="">Select expected volume of requests (number of news items per day)</option>
                <option value="Low Volume">Below 50 news items per day</option>
                <option value="Medium Volume">Between 50 and 100 news items per day</option>
                <option value="High Volume">More than 100 news items per day</option>
              </select>
            </div>
          </div>

          <div className="apikey-buttons">
            <button type="submit" className="apikey-request-btn">
              Submit request
            </button>
          </div>

          {error && <p className="apikey-request-error">{error}</p>}
          {message && <p className="apikey-request-message">{message}</p>}
        </form>

        <div className="apikey-manage-section">
          <label>Manage API keys</label>
          <p className="apikey-description">
            Below you can see your currently requested / assigned API keys and
            their associated status. An API key is available for use if its status is set to 'Active'. 
            Please note that API keys are unique to each user and must not be shared with third parties.
          </p>

          {apiKeys.length === 0 ? (
            <p>You currently do not have any API keys</p>
          ) : (
            <table className="apikey-table">
              <thead>
                <tr>
                  <th>Information source</th>
                  <th>API key</th>
                  <th>Created</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {apiKeys.map((keyObj) => {
                  const maskedKey = showFullKey
                    ? keyObj.user_apikey
                    : maskApiKey(keyObj.user_apikey);

                  return (
                    <tr key={keyObj.id}>
                      <td>
                        {formData.source || 'Regxplora News Tracker'}
                      </td>
                      <td className="apikey-key-cell">
                        <span className="apikey-displayed-key">
                          {maskedKey}
                        </span>
                        <div className="apikey-key-buttons">
                          <button
                            className="apikey-toggle-btn"
                            onClick={() => setShowFullKey(!showFullKey)}
                          >
                            {showFullKey ? 'Hide' : 'Show'}
                          </button>
                          <button
                            className="apikey-copy-btn"
                            onClick={() => handleCopyKey(keyObj.user_apikey)}
                          >
                            Copy
                          </button>
                        </div>
                      </td>
                      <td>{keyObj.created}</td>
                      <td>{keyObj.status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </main>
    </div>
  );
}

export default APIKeys;