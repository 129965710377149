import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import 'chartjs-adapter-date-fns';
import { Bar, Line, Bubble } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  TimeScale, 
  LineController,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip, 
} from 'chart.js';

ChartJS.register(TimeScale, LineController, LinearScale, PointElement, LineElement, BarElement, Tooltip);


const Dashboard = () => {
  const categories = [
    "Alert and warning",
    "Cooperation",
    "Data and reporting",
    "Enforcement",
    "Events and speeches",
    "Licensing and authorization",
    "Monetary policy",
    "Organizational affairs",
    "Policy and regulation",
    "Research",
    "Projects and initiatives",
    "Strategy and priorities",
    "Supervision",
    "Other"
  ];

  const regions = [
    "Total",
    "Asia",
    "Africa",
    "Caribbean",
    "Europe",
    "Latin America",
    "Middle East",
    "North America",
    "Pacific",
    "Global",
  ];

  const [heatmapData7Days, setHeatmapData7Days] = useState({});
  const [heatmapData30Days, setHeatmapData30Days] = useState({});
  const [heatmapData90Days, setHeatmapData90Days] = useState({});
  const [selectedPeriod, setSelectedPeriod] = useState('7 days');

  const [selectedCat, setSelectedCat] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const [selectedChartOption, setSelectedChartOption] = useState('Volume of news items');

  const [selectedRightOption, setSelectedRightOption] = useState('Recent news highlights');

  const [lineData, setLineData] = useState([]);

  const [bubbleData, setBubbleData] = useState([]);

  const [newsData, setNewsData] = useState(null);

  const getKeyDevPeriodKey = (period) => {
    if (period === '7 days') return '7Days';
    if (period === '30 days') return '30Days';
    if (period === '90 days') return '90Days';
    return null;
  };

  useEffect(() => {
    fetch('/api/heatmap_data')
      .then((res) => res.json())
      .then((data) => {
        setHeatmapData7Days(data.data_7_days);
        setHeatmapData30Days(data.data_30_days);
        setHeatmapData90Days(data.data_90_days);
      })
      .catch((err) => console.error(err));
  }, []);

  const displayedData = selectedPeriod === '7 days' 
      ? heatmapData7Days 
      : (selectedPeriod === '30 days' ? heatmapData30Days : heatmapData90Days);

  const getColorForValue = (percentage) => {
    if (percentage === 0) {
      return '#FCEAE0';  
    } else if (percentage > 0 && percentage <= 5) {
      return '#F6C6AD'; 
    } else if (percentage > 5 && percentage <= 10) {
      return '#F19065'; 
    } else if (percentage > 10 && percentage <= 20) {
      return '#EB652B';
    } else {
      return '#C04F15'; 
    }
  };

  const handleCellClick = (cat, region, rIdx) => {
    if (rIdx !== 0) {
      setSelectedCat(cat);
      setSelectedRegion(region);
    }
  };

  let factsheetTitle = "Factsheet";
  if (selectedCat && selectedRegion) {
    factsheetTitle = `Factsheet: ${selectedCat} - ${selectedRegion}`;
  }

  useEffect(() => {
    if (selectedCat && selectedRegion && selectedChartOption === 'Volume of news items') {
      const periodDays = selectedPeriod === '7 days' ? 7 : (selectedPeriod === '30 days' ? 30 : 90);
      const url = `/api/factsheet_line_data?category=${encodeURIComponent(selectedCat)}&region=${encodeURIComponent(selectedRegion)}&period=${periodDays}`;

      fetch(url)
        .then(res => res.json())
        .then(data => {
          setLineData(data.data || []);
        })
        .catch(err => console.error(err));
    } else {
      setLineData([]);
    }
  }, [selectedCat, selectedRegion, selectedPeriod, selectedChartOption]);


  useEffect(() => {
    if (selectedRegion && selectedCat) {
      const periodKey = selectedPeriod === '7 days' 
        ? '7days' 
        : (selectedPeriod === '30 days' 
          ? '30days' 
          : '90days');
      const url = `/api/factsheet_data?region=${encodeURIComponent(selectedRegion)}&category=${encodeURIComponent(selectedCat)}&period=${periodKey}`;
  
      fetch(url)
        .then(res => res.json())
        .then(data => setNewsData(data))
        .catch(err => console.error(err));
    } else {
      setNewsData(null);
    }
  }, [selectedRegion, selectedCat, selectedPeriod]);


  useEffect(() => {
    if (selectedCat && selectedRegion && selectedChartOption === 'Key topics') {
      const periodDays = selectedPeriod === '7 days' ? 7 : (selectedPeriod === '30 days' ? 30 : 90);
      const url = `/api/bubble_chart_data?category=${encodeURIComponent(selectedCat)}&region=${encodeURIComponent(selectedRegion)}&period=${periodDays}`;
  
      fetch(url)
        .then(res => res.json())
        .then(data => {
          console.log('Bubble chart data received:', data.data); 
          setBubbleData(data.data || []);
        })
        .catch(err => console.error(err));
    } else {
      setBubbleData([]);
    }
  }, [selectedCat, selectedRegion, selectedPeriod, selectedChartOption]);

  const chartData = {
    labels: lineData.map((d) => d.date),
    datasets: [
      {
        type: 'bar',
        label: 'Volume Trend',
        data: lineData.map((d) => d.count),
        borderColor: '#eb652b', 
        backgroundColor: '#eb652b',
        hoverBackgroundColor: '#C04F15', 
      }
    ],
  };
  
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            const value = context.parsed.y;
            return `Number of news items: ${value}`;
          }
        }
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'yyyy-MM-dd',
        },
        grid: { display: false },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 4,
          font: {
            size: 10,
          },
        },
      },
      y: {
        display: false,
      },
    },
    interaction: { mode: 'index', intersect: false },
  };

  const bubbleChartData = {
    datasets: [
      {
        label: 'Key Topics',
        data: bubbleData.map(item => ({
          x: item.X_Position,
          y: item.Y_Position,
          r: Math.sqrt(item.BubbleSize) * 5, 
          topic: item.Topic,
          authorityCount: item.AuthorityCount, 
          daysSinceLastMention: item.DaysSinceLastMention 
        })),
        backgroundColor: '#eb652b',
        borderColor: '#eb652b',
        hoverBackgroundColor: '#192841',
        hoverBorderColor: '#192841',
      },
    ],
  };

  const bubbleChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false, 
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataPoint = context.raw;
            const numberOfNewsItems = Math.round(dataPoint.r / 5) ** 2; 
            return [
              `Topic: ${dataPoint.topic}`,
              `Number of news items: ${numberOfNewsItems}`,
              `Authority count: ${dataPoint.authorityCount}`,
              `Days since last mention: ${dataPoint.daysSinceLastMention}`
            ].join(', ');
          },
        },
      },
    },
    interaction: {
      mode: 'point',
      intersect: true,
    },
    elements: {
      point: {
        borderWidth: 1,
        hoverBorderWidth: 2,
        opacity: 0.8, 
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Recency',
          font: {
            size: 10,
          },
        },
        ticks: {
          stepSize: 1,
          font: {
            size: 10, 
          },
          callback: function(value, index, values) {
            if (value === 1) return 'Least Recent';
            if (value === 2) return 'Moderately Recent';
            if (value === 3) return 'Recent';
            return '';
          },
        },
        min: 0,
        max: 4,
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Authority Count',
          font: {
            size: 10,
          },
        },
        ticks: {
          stepSize: 1,
          font: {
            size: 10, 
          },
          callback: function(value, index, values) {
            if (value === 1) return 'Low';
            if (value === 2) return 'Moderate';
            if (value === 3) return 'High';
            return '';
          },
        },
        min: 0,
        max: 4,
        grid: {
          display: false,
        },
      },
    },
  };

  const truncateSummary = (summary, wordCount) => {
    const words = summary.split(/\s+/);
    if (words.length <= wordCount) {
      return summary;
    }
    return words.slice(0, wordCount).join(' ') + '...';
  };


  return (
    <main className="dashboard-main">
      <header className="dashboard-header" style={{ alignItems: "center" }}>
        <h2 className="dashboard-title roboto-light">DASHBOARD</h2>
      </header>
      <p className="dashboard-description">
      Regxplora’s Dashboard provides a consolidated and interactive view of updates across multiple dimensions. The heatmap highlights key areas of activity by nature of update and region, enabling you to identify patterns and hotpots. With a simple click you can further drill down into specific developments in the factsheet section, which provides further details on the volumes of updates for a given area, key topics complete with a qualitative overview of key developments and selected recent news highlights. The dashboard is currently in beta and will be further expanded in the coming months. 
      </p>

      <div className="heatmap-container">
        <div className="heatmap-title"><strong>News Items by nature of update and region</strong></div>
        <div className="heatmap-divider"></div>
        <div className="heatmap-header">
          <div className="heatmap-filter">
            <select 
              className="heatmap-select" 
              value={selectedPeriod} 
              onChange={(e) => setSelectedPeriod(e.target.value)}
            >
                <option>7 days</option>
                <option>30 days</option>
                <option>90 days</option>
            </select>
          </div>
        </div>
        <table className="heatmap-table">
          <thead>
            <tr>
              <th className="heatmap-sticky-col"></th>
              {regions.map((region, idx) => (
                <th key={idx}>{region}</th>
              ))}
            </tr>
          </thead>
          <tbody>
          {categories.map((cat, cIdx) => (
              <tr key={cIdx}>
                <td className="heatmap-sticky-col">{cat}</td>
                {regions.map((region, rIdx) => {
                  const cellData = displayedData[cat] && displayedData[cat][region];
                  const totalVal = cellData ? cellData.total : 0;
                  const pctVal = cellData ? cellData.percentage : 0;

                  let backgroundColor = '#192841';
                  if (rIdx !== 0) {
                    backgroundColor = getColorForValue(pctVal);
                  }

                  return (
                    <td key={rIdx}>
                      <div
                        className="heatmap-cell"
                        style={{
                          backgroundColor: backgroundColor,
                          opacity: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: '#ffffff',
                          fontWeight: '300'
                        }}
                        onClick={() => handleCellClick(cat, region, rIdx)}
                      >
                        <span>{totalVal}</span>
                        <span>({pctVal}%)</span>
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="factsheet-container">
        <div className="factsheet-title"><strong>{factsheetTitle}</strong></div>
        <div className="factsheet-divider"></div>

        <div className="factsheet-content">
          <div className="factsheet-left">
            <select 
              className="heatmap-select" 
              value={selectedChartOption} 
              onChange={(e) => setSelectedChartOption(e.target.value)}
            >
              <option>Volume of news items</option>
              <option>Key topics</option>
            </select>

            {(!selectedCat || !selectedRegion) && (
              <div className="factsheet-message" style={{ marginTop: '20px', color: '#192841', fontWeight: 'normal', fontSize: '12px' }}>
                Please click on heatmap cell to view details
              </div>
            )}

            {selectedCat && selectedRegion && selectedChartOption === 'Volume of news items' && lineData.length > 0 && (
              <div style={{ marginTop: '20px' }}>
                <Bar data={chartData} options={chartOptions} />
              </div>
            )}

            {selectedCat && selectedRegion && selectedChartOption === 'Key topics' && bubbleData.length > 0 && (
              <div style={{ marginTop: '20px' }}>
                <Bubble data={bubbleChartData} options={bubbleChartOptions} />
              </div>
            )}
          </div>

          <div className="factsheet-right">
            <select 
              className="heatmap-select"
              value={selectedRightOption}
              onChange={(e) => setSelectedRightOption(e.target.value)}
            >
              <option>Recent news highlights</option>
              <option>Key developments</option>
            </select>
            {selectedRightOption === 'Recent news highlights' && newsData && newsData.TopNewsItems && (
              <ul className="factsheet-text" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                {newsData.TopNewsItems.slice(0, 6).map(item => {
                  const truncated = truncateSummary(item.NewsSummary, 27);
                  return (
                    <li 
                      key={item.ID} 
                      style={{ marginBottom: '3px' }} 
                      title={item.NewsSummary} 
                    >
                      <a 
                        href={item.NewsLink} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{ color: "#000", textDecoration: "none" }}
                      >
                        <strong>{item.Country}:</strong> {truncated}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}


            {selectedRightOption === 'Key developments' && newsData && newsData.Summary && newsData.Summary.KeyDevelopments && (
              <div className="factsheet-text">
                {(() => {
                  const periodKey = getKeyDevPeriodKey(selectedPeriod);
                  const keyDevText = periodKey && newsData.Summary.KeyDevelopments[periodKey]
                    ? newsData.Summary.KeyDevelopments[periodKey]
                    : "Coming soon: curated trend and insights analysis";

                  return (
                    <p>
                      {keyDevText}
                    </p>
                  );
                })()}
              </div>
            )}

          </div>

          <p></p>
          <p></p>

        </div>
      </div>
    </main>
  );
};

export default Dashboard;