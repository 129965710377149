import React, { createContext, useContext, useState, useEffect } from 'react';

const ClipboardContext = createContext();

export const useClipboardContext = () => useContext(ClipboardContext);

export const ClipboardProvider = ({ children, isAuthenticated }) => {
  const [clipboardItems, setClipboardItems] = useState([]);

  useEffect(() => {
    const fetchClipboardItems = async () => {
      if (isAuthenticated) {
        const clipboardResponse = await fetch('/api/clipboard_items', { credentials: 'include' });
        if (clipboardResponse.ok) {
          const data = await clipboardResponse.json();
          setClipboardItems(data.clipboardItems);
        } else {
          console.error('Failed to fetch clipboard items');
        }
      } else {
        const storedItems = JSON.parse(sessionStorage.getItem('clipboardItems')) || [];
        setClipboardItems(storedItems);
      }
    };
    fetchClipboardItems();
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      sessionStorage.setItem('clipboardItems', JSON.stringify(clipboardItems));
    }
  }, [clipboardItems, isAuthenticated]);

  const addClipboardItem = async (item) => {
    if (isAuthenticated) {
      const response = await fetch('/api/clipboard_items', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ content: item }),
      });
      const data = await response.json();
      if (response.ok) {
        if (data.message === 'Item already exists') {
          console.log('Duplicate item detected on backend, not adding:', item);
          setClipboardItems((prevItems) => {
            const isAlreadyInState = prevItems.some(
              (clipboardItem) => clipboardItem.id === data.item.id
            );
            if (!isAlreadyInState) {
              return [data.item, ...prevItems];
            }
            return prevItems;
          });
        } else {
          setClipboardItems((prevItems) => [data.item, ...prevItems]);
        }
      } else {
        console.error('Failed to add clipboard item');
      }
    } else {
      setClipboardItems((prevItems) => {
        const isDuplicate = prevItems.some(
          (clipboardItem) => clipboardItem.content === item
        );
        if (!isDuplicate) {
          const newItem = { id: Date.now(), content: item, date: new Date() };
          const updatedItems = [newItem, ...prevItems];
          return updatedItems;
        } else {
          console.log('Duplicate item detected, not adding:', item);
          return prevItems;
        }
      });
    }
  };

  const removeClipboardItem = async (id) => {
    if (isAuthenticated) {
      const response = await fetch(`/api/clipboard_items/${id}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (!response.ok) {
        console.error('Failed to delete clipboard item');
      }
    }
    setClipboardItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const clearClipboard = async () => {
    if (isAuthenticated) {
      const response = await fetch('/api/clipboard_items/clear', {
        method: 'POST',
        credentials: 'include',
      });
      if (!response.ok) {
        console.error('Failed to clear clipboard items');
      }
    }
    setClipboardItems([]);
  };

  return (
    <ClipboardContext.Provider
      value={{ clipboardItems, addClipboardItem, removeClipboardItem, clearClipboard }}
    >
      {children}
    </ClipboardContext.Provider>
  );
};
