import React from "react";
import "./DashboardRestrictionModal.css"; 

const DashboardRestrictionModal = ({ onClose }) => (
  <div className="dashboard-restriction-overlay">
    <div className="dashboard-restriction-content">
      <div className="dashboard-restriction-header">
        <h3>NOTICE</h3>
        <button className="close-dashboard-restriction-button" onClick={onClose}>
          &times;
        </button>
      </div>
      <div className="dashboard-restriction-body">
        <p>
        The creation of automated alerts is currently unavailable as we are further optimizing our alert system. Please check back in the new year.
        </p>
      </div>
    </div>
  </div>
);

export default DashboardRestrictionModal;