import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ResetPassword.css';

function ResetPassword() {
  const { token } = useParams(); 
  const navigate = useNavigate(); 

  const [email, setEmail] = useState('');
  const [requestSuccessMessage, setRequestSuccessMessage] = useState('');
  const [requestErrorMessage, setRequestErrorMessage] = useState('');
  const [isRequestSubmitting, setIsRequestSubmitting] = useState(false);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetSuccessMessage, setResetSuccessMessage] = useState('');
  const [resetErrorMessage, setResetErrorMessage] = useState('');
  const [isResetSubmitting, setIsResetSubmitting] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleRequestSubmit = async (e) => {
    e.preventDefault();
    setIsRequestSubmitting(true);
    setRequestSuccessMessage('');
    setRequestErrorMessage('');

    try {
      const response = await fetch('/api/forgot_password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (response.ok) {
        setRequestSuccessMessage('If an account exists with this email, you will receive a password reset link.');
      } else {
        setRequestErrorMessage(data.error || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error requesting password reset:', error);
      setRequestErrorMessage('An error occurred. Please try again.');
    } finally {
      setIsRequestSubmitting(false);
    }
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleResetSubmit = async (e) => {
    e.preventDefault();
    setIsResetSubmitting(true);
    setResetSuccessMessage('');
    setResetErrorMessage('');

    if (newPassword !== confirmPassword) {
      setResetErrorMessage('Passwords do not match.');
      setIsResetSubmitting(false);
      return;
    }

    try {
      const response = await fetch(`/api/reset_password/${token}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ new_password: newPassword }),
      });
      const data = await response.json();
      if (response.ok) {
        setResetSuccessMessage('Your password has been reset successfully.');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else {
        setResetErrorMessage(data.error || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setResetErrorMessage('An error occurred. Please try again.');
    } finally {
      setIsResetSubmitting(false);
    }
  };

  return (
    <div className="reset-password-container">
      <main className="reset-password-main">
        <header className="reset-password-header">
          <h2 className="reset-password-title roboto-light">RESET PASSWORD</h2>
        </header>

        {!token ? (
          <>
            {requestSuccessMessage ? (
              <p className="reset-password-description">
                {requestSuccessMessage}
              </p>
            ) : (
              <p className="reset-password-description">
                Please enter your email address. You will receive a link to reset your password.
              </p>
            )}
            {!requestSuccessMessage && (
              <form className="reset-password-form" onSubmit={handleRequestSubmit}>
                <div className="reset-password-form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="reset-password-input"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                </div>
                <button
                  className="reset-password-section-btn"
                  type="submit"
                  disabled={isRequestSubmitting}
                >
                  {isRequestSubmitting ? 'Sending...' : 'RESET PASSWORD'}
                </button>
                {requestErrorMessage && (
                  <p className="reset-password-error-message">{requestErrorMessage}</p>
                )}
              </form>
            )}
          </>
        ) : (
          <>
            {resetSuccessMessage ? (
              <p className="reset-password-description">
                {resetSuccessMessage}
              </p>
            ) : (
              <form className="reset-password-form" onSubmit={handleResetSubmit}>
                <div className="reset-password-form-group">
                  <input
                    type="password"
                    id="new_password"
                    name="new_password"
                    className="reset-password-input"
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    required
                  />
                </div>
                <div className="reset-password-form-group">
                  <input
                    type="password"
                    id="confirm_password"
                    name="confirm_password"
                    className="reset-password-input"
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required
                  />
                </div>
                <button
                  className="reset-password-section-btn"
                  type="submit"
                  disabled={isResetSubmitting}
                >
                  {isResetSubmitting ? 'Saving...' : 'SAVE NEW PASSWORD'}
                </button>
                {resetErrorMessage && (
                  <p className="reset-password-error-message">{resetErrorMessage}</p>
                )}
              </form>
            )}
          </>
        )}
      </main>
    </div>
  );
}

export default ResetPassword;