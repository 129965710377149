import React from 'react';
import ReactDOM from 'react-dom';
import './Tooltip.css';

const Tooltip = ({ text, position, targetRect }) => {
  if (!targetRect) return null;

  const tooltipStyle = {
    top: targetRect.bottom + window.scrollY + 5, 
    left: targetRect.left + window.scrollX,      
    zIndex: 1000,                                
  };

  return ReactDOM.createPortal(
    <div className={`tooltip ${position}`} style={tooltipStyle}>
      {text}
      <span className="tooltip-arrow"></span>
    </div>,
    document.body
  );
};

export default Tooltip;
