import React from "react";
import "./Changelog.css";

const Changelog = () => {
  return (
    <div className="changelog-container">
      <main className="changelog-main">
        <header className="changelog-header" style={{ alignItems: "center" }}>
          <h2 className="changelog-title">CHANGELOG</h2>
        </header>

        <p className="changelog-description">
          Below you can find an overview of recent changes and additions to the platform, ordered by date.
        </p>

        <h2 className="changelog-subtitle">26 January, 2025</h2>
        <ul className="changelog-list">
          <li>Added a dedicated Developer Resources section including the ability to create new API keys for programmatic extraction of news from the News Tracker database</li>
          <li>Implemented several enhancements to the Newsfeed, improving the user experience and addressing remaining bugs</li>
        </ul>
      </main>
    </div>
  );
};

export default Changelog;