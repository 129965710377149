import React, { useState, useEffect } from 'react';
import './Profile.css'; 

function Profile({ setIsAuthenticated }) {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    country: '',
    organization: '',
    position: '',
  });
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchProfile = async () => {
      const response = await fetch('/api/profile', {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setFormData({
          ...formData,
          ...data,
          password: '', 
        });
      } else {
        setError('Failed to fetch profile data.');
      }
    };
    fetchProfile();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('/api/profile', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    if (response.ok) {
      setMessage('Profile updated successfully.');
    } else {
      setError(data.error || 'Failed to update profile.');
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete your profile? This action cannot be undone.')) {
      return;
    }
    const response = await fetch('/api/delete_profile', {
      method: 'DELETE',
      credentials: 'include',
    });
    const data = await response.json();
    if (response.ok) {
      setIsAuthenticated(false);
      setMessage('Profile successfully deleted.');
    } else {
      setError(data.error || 'Failed to delete profile.');
      setMessage('Profile could not be deleted at this time. Please try again later.');
    }
  };

  return (
    <div className="profile-container">
      <main className="profile-main">
        <header className="profile-header">
          <h2 className="profile-title roboto-light">MY PROFILE</h2>
        </header>

        <form className="profile-form" onSubmit={handleSubmit}>
          <div className="profile-row">
            <div className="profile-form-group">
              <label htmlFor="first_name" className="profile-label">First Name</label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                className="profile-input"
                placeholder="First Name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="profile-form-group">
              <label htmlFor="last_name" className="profile-label">Last Name</label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                className="profile-input"
                placeholder="Last Name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="profile-row">
            <div className="profile-form-group">
              <label htmlFor="email" className="profile-label">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="profile-input"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="profile-form-group">
              <label htmlFor="country" className="profile-label">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                className="profile-input"
                placeholder="Country"
                value={formData.country}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="profile-row">
            <div className="profile-form-group">
              <label htmlFor="organization" className="profile-label">Organization</label>
              <input
                type="text"
                id="organization"
                name="organization"
                className="profile-input"
                placeholder="Organization"
                value={formData.organization}
                onChange={handleChange}
              />
            </div>
            <div className="profile-form-group">
              <label htmlFor="position" className="profile-label">Position</label>
              <input
                type="text"
                id="position"
                name="position"
                className="profile-input"
                placeholder="Position"
                value={formData.position}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="profile-row">
            <div className="profile-form-group-full">
              <label htmlFor="password" className="profile-label">
                Password <span style={{ fontWeight: 'normal' }}>(Leave blank to keep current password)</span>
              </label>
              <input
                type="password"
                id="password"
                name="password"
                className="profile-input"
                placeholder="New Password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="profile-buttons">
            <button className="profile-section-btn profile-section-btn-save" type="submit">
              SAVE CHANGES
            </button>
            <button
              type="button"
              className="profile-section-btn profile-section-btn-delete"
              onClick={handleDelete}
            >
              DELETE PROFILE
            </button>
          </div>
          {error && <p className="profile-error">{error}</p>}
          {message && <p className="profile-message">{message}</p>}

        </form>
      </main>
    </div>
  );
}

export default Profile;
